#info-container {
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: .34fr .33fr .33fr;
    grid-template-areas:
        "picture"
        "desc"
        "soundcloud"
    ;
    background-color: transparent;
    gap: 2rem;
}

#pic-div {
    grid-area: picture;
    /* max-height: 450px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#pic {
    width: 400px;
    height: 400px;
    aspect-ratio: 4/3;
    object-fit: cover;
}

#pic-title {
    margin: 0;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.6rem;
    color: rgb(221, 221, 221);
    font-family: 'digitaldream';
}

.icons {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 7px 10px 0 10px;
    transition: transform 80ms ease-out;
}

.iconLinks:hover {
    .icons {
        transform: scale(1.15);
    }
}

.iconLinks:link {
    text-decoration: none;
}

#text-div {
    height: max-content;
    width: fit-content;
    grid-area: desc;
}

#artist-bio {
    text-align: start;
    margin: 0 10px 0 10px;
    padding: 0;
    font-size: 25px;
    color: white;
    font-family: 'Roboto Mono', monospace, 'Times New Roman', Times, serif;
    line-height: 32px;
    word-spacing: 0px;
}

#sc-div {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: soundcloud;
}

#sc-widget {
    width: 40rem;
    height: 9rem;
    font-size: 10px;
    color: #cccccc;
    line-break: anywhere;
    word-break: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;
    font-weight: 100;
    border-radius: 10px;
}