#header video {
    max-width: 100%;
    max-height: 100vh;
    object-fit: cover;
    position: fixed;
    z-index: -1;
  }

:root {
    --gradient: linear-gradient(
        45deg,
        #299369,
        #00ffff,
        #4B974B,
        #0eaf9c
        /* #4ca358,
        #b1E5a6 */
    );
}

@keyframes anim {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@keyframes fade {
    from {
        opacity: 0;
        transform: scale(.5);
    } to {
        opacity: 1;
        transform: scale(1);
    } 0% {
        opacity: 0;
        transform: scale(.5);
    } 50% {
        opacity: 1;
        transform: translate() scale(1);
    } 100% {
        transform: translate();
    }
}

#header {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#hdrTitle {
    text-align: center;
    margin: 0;
    font-family: 'zerohour';
    font-size: 4rem;
    background-color: transparent;
    text-shadow: 0 0 15px rgba(245, 245, 245, 0.233);
    animation: fade 8s ease;
}