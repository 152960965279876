@font-face {
  font-family: 'zerohour';
  src: local('zerohour'), url(../fonts/zerohour.woff2) format('woff2');
}

span {
  background-image: var(--gradient);
  background-size: 100%;
  background-position: left;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: anim 10s infinite alternate;
}

img {
  width: 14vh;
  height: 14vh;
}

body {
  padding: 0;
  margin: 0;
  background-color: black;
  overflow-x: hidden;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 1rem;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: auto;
  justify-content: space-between;
}

.container {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1230px) {
  div#info-container {
    grid-template-columns: .4fr .6fr;
    grid-template-rows: 1fr .5fr;
    grid-template-areas:
      "picture desc"
      "picture soundcloud"
    ;
  }

  div#main {
    height: 478.219px;
  }

  div#text-div {
    height: 271px;
  }

  div#gradient-main {
    padding-top: 75svh;
    padding-bottom: 15svh;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 35%, rgba(20,20,20,1) 55%);
  }
  
  .arrows {
    opacity: 0;
  }
  
  #main:hover .arrows {
    transition: opacity 0.4s ease-out;
    opacity: 1;
  }

  #main:not(:hover) .arrows {
    transition: opacity 0.4s ease-out;
  }
}

@media only screen and (max-width: 924px) {
  html {
    width: 924px;
    overflow-x: hidden;
  }

  div#info-container {
    margin-bottom: 2%;
  }
  
  div#main {
    transform: scale(1.1);
  }

  p.otherFoot {
    font-size: 1rem;
    margin: 0;
  }

  .icons {
    margin-bottom: 2svh;
    /* margin-bottom: 20px; */
  }

  a.footLinks {
    padding: 10px;
  }
}