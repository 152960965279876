#footer {
    height: min-content;
    color: rgb(108, 108, 108);
    box-shadow: 0 0px 20px rgb(55, 55, 55);
    bottom: 0;
    width: 100%;
    background-color: rgb(22, 22, 22);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.otherFoot {
    color: rgb(160, 160, 160);
    font-family: Arial;
    font-size: .8rem;
    margin-right: auto;
    margin-left: auto;
    text-decoration: underline;
}

#footContent {
    margin-top: 10px;
    text-decoration: none;
    font-family: Arial;
    font-size: 32px;
    min-width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footLinks {
    transition: all 0.2s;
}

.footLinks:link, .footLinks:visited, .footLinks:active {
    color: rgb(89, 89, 89);
    text-decoration: none;
}

.footLinks:hover {
    color: rgb(126, 126, 126);
    transform: scale(1.2);
}