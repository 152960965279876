#gradient-main {
    width: 100%;
    background: rgb(0,0,0);
    padding-bottom: 4vh;
    padding-top: 32vh;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(20,20,20,1) 35%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#main {
    width: min-content;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
}

#indexed-dots {
    margin: 5% 0 0 0;

    width: 50px;
    height: 25px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dots {
    background-color: rgb(89,89,89);

    border-radius: 50%;

    width: 7px;
    height: 7px;

    transition: all 0.2s;

    display: inline-block;
}

#currentDot {
    width: 15px;
    height: 15px;

    background-color: rgb(126, 126, 126);
}

.btn-nav {
    height: 50px;
    width: 80px;
    display: grid;
    place-items: center;
    cursor: pointer;
}

#back {
    grid-column: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

#forward {
    grid-column: 3;
}

.arrows {
    border: solid white;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 13px;
    opacity: 1;

    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}